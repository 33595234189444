import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "dealing-with-a-database"
    }}>{`Dealing with a database`}</h1>
    <p>{`S3 is great for static files, but what if you want a little more?`}</p>
    <p>{`For dynamic data, we'll use a database. DynamoDB integrates nicely with the Serverless ecosystem and doesn't require a lot of setup.`}</p>
    <p>{`Choosing a database for your project in the real world is a matter of balancing various factors.`}</p>
    <h2 {...{
      "id": "create-a-dynamodb-table"
    }}>{`Create a DynamoDB table`}</h2>
    <p>{`DynamoDB stores data in tables. Each works as its own database and you can have as many as you want.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Move into exercise-6 in the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises"
      }}>{`exercises repository`}</a>{`.`}</p>
    <p>{`Create a new YAML file to specify a new DynamoDB table.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# dynamodb.yml
Resources:
  ItemsTable:
    Type: "AWS::DynamoDB::Table"
    Properties:
      AttributeDefinitions:
        - AttributeName: itemId
          AttributeType: S
      KeySchema:
        - AttributeName: itemId
          KeyType: HASH
      ProvisionedThroughput:
        ReadCapacityUnits: 1
        WriteCapacityUnits: 1
      TableName: \${self:provider.environment.ITEMS_TABLE}
`}</code></pre>
    <p>{`This creates a DynamoDB table with an identifier.`}</p>
    <p>{`Add this table to your serverless config. Don't forget to install `}<inlineCode parentName="p">{`serverless-resources-env`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml
resources:
  - \${file(dynamodb.yml)}

plugin:
  - serverless-resources-env
`}</code></pre>
    <p>{`You can now access your DynamoDB's name as `}<inlineCode parentName="p">{`process.env.CF_ItemsTable`}</inlineCode></p>
    <p>{`You'll need to grant access and specify the name. I recommend starting with liberal permissions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

provider:
  environment:
    ITEMS_TABLE: \${self:service}-items-\${self:provider.stage}
  iamRoleStatements:
    - Effect: Allow
      Action:
        - dynamodb:Query
        - dynamodb:Scan
        - dynamodb:GetItem
        - dynamodb:PutItem
        - dynamodb:UpdateItem
        - dynamodb:DeleteItem
      Resource: "arn:aws:dynamodb:\${opt:region, self:provider.region}:*:table/\${self:provider.environment.ITEMS_TABLE}"
`}</code></pre>
    <p>{`Install the `}<inlineCode parentName="p">{`simple-dynamodb`}</inlineCode>{` package. It's a thin wrapper I built to help with common DynamoDB operations.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/2f0f402eba4e62963936ca9be0c16e115cfe6edc#diff-5798e57392a10541e65cb482320a0501"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/2f0f402eba4e62963936ca9be0c16e115cfe6edc#diff-5798e57392a10541e65cb482320a0501`}</a></p>
    <h2 {...{
      "id": "write-to-your-table"
    }}>{`Write to your table`}</h2>
    <p>{`Before you can read from a table, you have to store something. We're going to treat every write as an upsert for simplicity.`}</p>
    <p>{`Create item on first call, update on subsequent calls.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Create a new lambda function handling a POST request. Accept a JSON payload and an `}<inlineCode parentName="p">{`itemId`}</inlineCode>{` path param.`}</p>
    <p>{`Save the JSON object in your table with code like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import * as db from "./dynamodb"

const updatedAt = new Date().toISOString()

// db.buildExpression and db.buildAttributes are helper functions to create DynamoDB queries
const item = await db.updateItem({
  TableName: process.env.ITEMS_TABLE!,
  Key: { itemId },
  UpdateExpression: \`SET \${db.buildExpression(body)}, updatedAt = :updatedAt\`,
  ExpressionAttributeValues: {
    ...db.buildAttributes(body),
    ":updatedAt": updatedAt,
  },
  ReturnValues: "ALL_NEW",
})
`}</code></pre>
    <p>{`You can access saved values via `}<inlineCode parentName="p">{`item.Attributes`}</inlineCode>{`. I recommend returning them.`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/update/1" jsonPlaceholder={`{"hello": "world"}`} mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/8cb7d1617f5370948a093954a77c9f8fe4bf0868#diff-5798e57392a10541e65cb482320a0501"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/8cb7d1617f5370948a093954a77c9f8fe4bf0868#diff-5798e57392a10541e65cb482320a0501`}</a></p>
    <h2 {...{
      "id": "read-from-your-table"
    }}>{`Read from your table`}</h2>
    <p>{`Reading from your table is easier. My wrapper provides a helpful `}<inlineCode parentName="p">{`db.getItem`}</inlineCode>{` method.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Create a new function that handles a GET request. Use a path param for the ID.`}</p>
    <p>{`Use code like this to read from your table.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// read itemId from pathParameters

const item = await db.getItem({
  TableName: process.env.ITEMS_TABLE!,
  Key: { itemId },
})
`}</code></pre>
    <p>{`Return `}<inlineCode parentName="p">{`item.Item`}</inlineCode>{` if found. 404 status code if not.`}</p>
    <TestCloudFunction serviceName="serverless" urlPlaceholder="<domain>/get/1" jsonPlaceholder="" mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/commit/6cafbbd4f6d832652f8880c218615b4327faeee7#diff-5798e57392a10541e65cb482320a0501"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/commit/6cafbbd4f6d832652f8880c218615b4327faeee7#diff-5798e57392a10541e65cb482320a0501`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      